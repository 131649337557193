<template>
	<div
		class="fixed top-0 right-0 z-2000 flow-root h-full ax-scrollbar-thin w-[var(--quicknote-width)] max-w-full bg-white/95 px-8 pt-4 pb-15 sm:border-l lg:bg-white"
		ref="quickNoteRef"
		role="dialog"
		aria-modal="true"
		aria-labelledby="quicknote-title"
	>
		<div
			class="absolute top-0 left-0 h-full w-1 cursor-ew-resize bg-transparent hover:bg-slate-300/50"
			@mousedown="
				(event) => quickNoteRef && startResize(event, quickNoteRef)
			"
			role="separator"
			aria-orientation="vertical"
		/>
		<button
			class="absolute top-5 right-7 text-slate-500 transition-colors hover:text-slate-900"
			@click="closeQuickNote()"
			:aria-label="$s('Core.Button.Close')"
		>
			<Icon :name="ICON_CLOSE" class="size-5" aria-hidden="true" />
		</button>

		<header class="mr-10 flex items-center justify-between gap-x-3">
			<h2 id="quicknote-title" class="ax-main-heading mb-0">
				{{ $p('Core.Label.QuickNoteHeader') }}
			</h2>

			<Button
				theme="success"
				@click="toggleEditing"
				size="xsmall"
				:icon="ICON_NOTE"
			>
				{{ $s('Core.Label.NewAlt') }}
				{{ $sl('Core.Label.QuickNote') }}
			</Button>
		</header>

		<div class="mt-4 flex h-full flex-col gap-y-3">
			<div class="max-h-64">
				<ErrorMessage v-if="error" :error="error" />

				<div v-else-if="isLoading" class="mt-5 flex justify-center">
					<LoadingSpinner />
				</div>

				<ul
					v-else-if="!isLoading && quickNoteList?.length"
					class="flex h-full ax-scrollbar-thin flex-col divide-y overflow-y-auto overscroll-contain rounded border"
					role="region"
					aria-label="Quick notes list"
				>
					<li v-for="note in quickNoteList" :key="note.id">
						<button
							@click="toggleEditing(note)"
							class="block w-full truncate px-3 py-2 text-left text-xs transition-colors"
							:data-quicknote-id="note.id"
							:class="{
								'bg-primary-50 font-semibold':
									currentNoteId === note.id,
								'bg-white font-medium hover:bg-slate-100':
									currentNoteId !== note.id,
							}"
						>
							{{ getBody(note) }}
						</button>
					</li>
				</ul>

				<div
					v-else
					class="flex h-full items-center justify-center rounded-lg border p-4"
				>
					<p class="text-sm text-slate-400">
						{{ $s('Core.Label.NoQuickNotes') }}
					</p>
				</div>
			</div>

			<template v-if="isEditing">
				<div class="mt-5 flex justify-between gap-2">
					<div>
						<h2
							class="-mt-1 ax-sub-heading"
							:aria-label="
								currentNoteId
									? $s('Core.Label.EditingQuickNote')
									: $s('Core.Label.NewAlt') +
										' ' +
										$sl('Core.Label.QuickNote')
							"
						>
							<template v-if="currentNoteId">
								{{ $s('Core.Label.EditingQuickNote') }}
							</template>
							<template v-else>
								{{ $s('Core.Label.NewAlt') }}
								{{ $sl('Core.Label.QuickNote') }}
							</template>
						</h2>

						<p class="-mt-2 text-xs text-slate-400">
							{{ $s('Core.Label.QuickNoteHelp') }}
						</p>
					</div>

					<PopoverNative width="auto" origin="right">
						<template #button>
							<Button
								theme="neutral"
								size="xsmall"
								:icon="ICON_TRASH"
								:aria-label="$s('Core.Button.Delete')"
							/>
						</template>

						<div class="p-1">
							<Button
								theme="danger"
								size="xsmall"
								@click="deleteNote"
								:label="$s('Core.Button.Delete')"
								:aria-label="$s('Core.Button.Delete')"
								class="w-40"
							>
								{{ $s('Core.Button.Delete') }}
								{{ $sl('Core.Label.QuickNote') }}
							</Button>
						</div>
					</PopoverNative>
				</div>

				<div class="min-h-64 flex-1 shrink-0 grow">
					<FormKit
						:key="formKey"
						type="richtext"
						name="body"
						v-model="body"
						:autofocus="true"
						:full-height="true"
						:disabled="isCreating"
						:classes="{
							outer: 'relative h-full',
						}"
					/>
				</div>
			</template>
		</div>

		<!-- Saving-->
		<div
			v-if="isSaving"
			class="fixed right-4 bottom-4 z-50 flex gap-3 rounded-full border border-primary-100 bg-primary-50 px-5 py-2 text-primary-950 shadow-xl"
		>
			<span class="font-medium">{{
				$s('Core.Label.Autosaving') + '…'
			}}</span>
			<LoadingSpinner />
		</div>
	</div>
</template>

<script setup lang="ts">
import { QuickNoteItem, QuickNoteInput } from '~/codegen/axos-client';

const isEditing = ref(true);
const currentNoteId = ref<number | null>(null);
const formKey = ref(0);
const isSaving = ref(false);
const body = ref('');
const initialBody = ref('');

const { api } = useApiClient();
const { closeQuickNote, initializeWidth, startResize } = useQuickNoteUtils();

// Quick note list
const {
	data: quickNoteList,
	error,
	isLoading,
} = useQuery({
	queryKey: [QUICKNOTE_BASE_KEY, 'quickNoteList'],
	queryFn: () => api.getQuickNoteList(),
});

// Mutations
const { mutateAsync: createQuickNoteMutation, isPending: isCreating } =
	useMutation({
		mutationFn: (data: QuickNoteInput) => api.createQuickNote(data),
	});

const { mutateAsync: updateQuickNoteMutation } = useMutation({
	mutationFn: async (data: QuickNoteInput) =>
		api.updateQuickNote(currentNoteId.value!, data),
});

const { mutateAsync: deleteQuickNoteMutation } = useMutation({
	mutationFn: async (id: number) => api.deleteQuickNote(id),
});

// Debounced save with error handling

watchDebounced(
	[body],
	async () => {
		if (currentNoteId.value && body.value === initialBody.value) return;

		try {
			if (currentNoteId.value) {
				await updateNote();
			} else {
				await createNote();
			}
			error.value = null;
		} catch (err) {
			error.value = err as Error;
		}
	},
	{
		debounce: 1000,
		deep: true,
		maxWait: 10000,
	},
);

async function deleteNote() {
	if (!currentNoteId.value) return;

	try {
		await deleteQuickNoteMutation(currentNoteId.value);
		$success($s('Core.Info.QuickNoteDeleted'));
		toggleEditing();
		error.value = null;
	} catch (err) {
		error.value = err as Error;
	}
}

// Optimized body text extraction
function getBody(row: QuickNoteItem): string {
	const noteLength = 50;
	const tempDiv = document.createElement('div');
	tempDiv.innerHTML = row.body;

	const walk = document.createTreeWalker(tempDiv, NodeFilter.SHOW_TEXT);
	const firstTextNode = walk.nextNode();
	const textContent = firstTextNode?.textContent?.trim() || '';

	return textContent.length > noteLength
		? `${textContent.substring(0, noteLength)}…`
		: textContent;
}

async function createNote() {
	if (!body.value?.trim()) return;

	try {
		showSaving();
		const { id } = await createQuickNoteMutation({ body: body.value });
		currentNoteId.value = id;
		initialBody.value = body.value;

		// Wait for the query to be updated with the new item
		await nextTick();
		await new Promise((resolve) => setTimeout(resolve, 100));
		scrollToActiveItem();
	} catch (err) {
		throw err;
	}
}

async function updateNote() {
	if (!currentNoteId.value || !body.value?.trim()) return;

	try {
		showSaving();
		await updateQuickNoteMutation({ body: body.value });
		initialBody.value = body.value;
		scrollToActiveItem();
	} catch (err) {
		throw err;
	}
}

function scrollToActiveItem() {
	nextTick(() => {
		const activeItem = document.querySelector(
			`[data-quicknote-id="${currentNoteId.value}"]`,
		);
		if (activeItem) {
			activeItem.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
		}
	});
}

function toggleEditing(row?: QuickNoteItem): void {
	if (row) {
		isEditing.value = true;
		body.value = row.body;
		initialBody.value = row.body;
		currentNoteId.value = row.id;
	} else {
		isEditing.value = !isEditing.value;
		if (isEditing.value) {
			body.value = '';
			initialBody.value = '';
			currentNoteId.value = null;
		}
	}
	formKey.value++;
	error.value = null;
}

function showSaving() {
	isSaving.value = true;

	setTimeout(() => {
		isSaving.value = false;
	}, 1000);
}

// Width and resizing
const quickNoteRef = ref<HTMLElement | null>(null);

// Initialize on mount
onMounted(() => {
	initializeWidth();
});
</script>
