<template>
	<template
		v-if="
			profile &&
			capabilities &&
			modules &&
			localizationData &&
			instanceSettings
		"
	>
		<div
			class="relative mt-[var(--header-height)] flex-1 lg:mt-[var(--header-height-lg)]"
			data-test="auth-context-logged-in"
		>
			<AppToasts />
			<AppNotifications />

			<AppHeader />

			<!-- Quick note-->
			<Teleport v-if="isQuickNoteOpen" to="html">
				<QuickNoteList />
			</Teleport>

			<ErrorBoundary>
				<!-- Axos Layout -->
				<slot />

				<template #error="{ error, clearError }">
					<ErrorPage404
						v-if="error?.statusCode === 404"
						:error="error"
						@clear-error="clearError"
					/>
					<ErrorPage403
						v-else-if="error?.statusCode === 403"
						:error="error"
						@clear-error="clearError"
					/>
					<ErrorPageDefault
						v-else
						:error="error"
						@clear-error="clearError"
					/>
				</template>
			</ErrorBoundary>

			<ModalsContainer />

			<transition name="fade">
				<div
					v-if="profile && profile.isImpersonated"
					class="fixed right-4 bottom-4 z-20 flex gap-3 rounded-full border border-primary-200 bg-primary-100 px-5 py-2 shadow-xl"
				>
					<p class="font-medium text-slate-600">
						Du jobber nå som
						<span class="font-semibold text-primary-800">
							{{ profile.firstName }}
							{{ profile.lastName }}
						</span>
					</p>
				</div>
			</transition>
		</div>
	</template>

	<template v-else>
		<div
			class="animate-fade-in flex h-screen w-screen flex-col items-center justify-center bg-white"
		>
			<AxosLogo class="h-24 fill-primary" />

			<LoadingBubbles />
		</div>
	</template>
</template>

<script lang="ts" setup>
import { HubConnectionState, HubConnection } from '@microsoft/signalr';
import { ModalsContainer } from 'vue-final-modal';
import * as Sentry from '@sentry/vue';

// TODO: Add caching! :)
const { localizationData, suspense } = useLocalizationData();
await suspense();

const { setResourcesData, setLanguageId, missingResources } = useLocalization();
setResourcesData(localizationData.value!);

const { mutateAsync: reportMissingResources } = useMutation({
	mutationFn: (resources: string[]) =>
		$api.reportMissingResources({ resources }),
});
watchDebounced(
	missingResources,
	() => {
		reportMissingResources(missingResources.value);
	},
	{ debounce: 5000 },
);

const { suspense: suspenseNumberings } = useNumberings();
await suspenseNumberings();

const { currentLanguageId } = useAppLanguage();
watch(
	currentLanguageId,
	(newId) => {
		if (newId) {
			setLanguageId(newId);
		}
	},
	{ immediate: true },
);

const { isQuickNoteOpen } = useQuickNoteUtils();
const { profile } = useProfile();
const { capabilities } = useCapabilities();
const { modules } = useModules();
const { currentClient } = useAuthClient();

const { instanceSettings } = useInstanceSettings();
const { data: instanceSettingsData } = useQuery({
	queryKey: ['instanceSettings'],
	queryFn: () => $api.getPublicInstanceSettings(),
	staleTime: Number.POSITIVE_INFINITY,
});
watch(instanceSettingsData, (data) => {
	if (data) {
		instanceSettings.value = data;
		localStorage.setItem('instanceSettings', JSON.stringify(data));
	}
});

watchEffect(() => {
	if (profile.value && currentClient.value) {
		Sentry.setUser({
			id: profile.value.id,
			email: profile.value.email,
			clientId: currentClient.value.id,
			clientName: currentClient.value.name,
		});
	}
});

try {
	const app = useNuxtApp();
	const signalRConnection: HubConnection | null = app.$signalR as any;

	if (
		signalRConnection &&
		signalRConnection.state !== HubConnectionState.Connected
	) {
		signalRConnection
			.start()
			.then(() => console.log('SignalR connection started'))
			.catch((err) => console.error('SignalR connection failed: ', err));
	}
} catch (error) {
	console.error(error);
}
// const { unreadCount } = useNotifications();
const unreadCount = ref(0);

const favicon = computed(() =>
	unreadCount.value && unreadCount.value > 0
		? '/favicon-unread.svg'
		: '/favicon.svg',
);

// Impersonate
const queryClient = useQueryClient();

const { impersonatingId } = useImpersonate();
watch(impersonatingId, () => {
	setTimeout(() => {
		queryClient.resetQueries();
	}, 250);
});

useFavicon(favicon);

useHead({
	templateParams: {
		site: {
			name: 'Axos',
		},
		separator: '·',
		subPage: null,
		unreadCount: computed(() =>
			unreadCount.value ? `(${unreadCount.value?.toString()})` : null,
		),
	},
	titleTemplate: '%unreadCount %subPage %separator %s %separator %site.name',
	meta: [{ name: 'theme-color', content: '#0059ff' }],
	link: [
		{ rel: 'apple-touch-icon', sizes: '192x192', href: '/touch-icon.png' },
	],
	bodyAttrs: {
		class: 'text-slate-800 bg-slate-75 lg:bg-white flex-1 relative',
	},
	htmlAttrs: {
		class: 'overflow-x-hidden bg-slate-75 flex',
	},
});
</script>
